<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-5 py-2 col-sm-12">
            <label for="input-large">Username</label>
            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Username"
              v-model="filter.username"
            ></b-form-input>
          </div>

          <div class="col-md-5 py-2 col-sm-12">
            <label for="input-large">Name</label>
            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Name"
              v-model="filter.name"
            ></b-form-input>
          </div>

          <div class="col-md-2 py-2 col-sm-12">
            <label for="input-large">&nbsp;</label>
            <div>
              <button class="btn btn-primary btn-md" @click="fnFilterUser">
                <i class="flaticon2-search-1"></i> Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card card-custom p-5 gutter-b">
        <div class="d-flex my-3 justify-content-start">
          <template>
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    <i class="flaticon-add"></i> Add User
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Form Tambah User</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form @submit.prevent="addUser" id="add-user">
                      <v-container>
                        <v-row>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-autocomplete
                              label="PIC"
                              v-model="filter.pic"
                              :items="formattedDrafterPicOptions"
                              @change="updatePicData(filter, i)"
                              :filter="customFilterPIC"
                              item-text="text"
                              solo
                            ></v-autocomplete>
                          </div>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-text-field
                              label="Name*"
                              id="input-small"
                              v-model="form.name"
                              solo
                              required
                            ></v-text-field>
                          </div>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-text-field
                              label="Username*"
                              v-model="form.username"
                              solo
                              required
                            ></v-text-field>
                          </div>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-text-field
                              label="Email*"
                              v-model="form.email"
                              solo
                              type="email"
                              required
                            ></v-text-field>
                          </div>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-text-field
                              label="Password*"
                              v-model="form.password"
                              type="password"
                              required
                              solo
                            ></v-text-field>
                          </div>
                          <div class="col-md-12 py-2 col-sm-12 text-left">
                            <v-select
                              label="Roles*"
                              v-model="form.role"
                              :items="formattedRoleOptions"
                              required
                              solo
                            ></v-select>
                          </div>
                        </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="background-color: whitesmoke;"
                      @click="dialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      class="btn btn-success mr-2"
                      :disabled="loading"
                      type="submit"
                      form="add-user"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </div>

        <div class="card-body p-0">
          <complete-table :loading="loading" :data="dataset">
            <template #header>
              <th style="min-width: 50px;" class="pl-7">
                <span class="text-dark-75">ID</span>
              </th>
              <th style="min-width: 100px;">Name</th>
              <th style="min-width: 100px;">Email</th>
              <th style="min-width: 100px;">Username</th>
              <th style="min-width: 100px;">Pic</th>
              <th style="min-width: 100px;">Role</th>
              <th style="min-width: 101px;">Action</th>
            </template>

            <template #defaultBody="{ item: user, i }">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.id }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.name }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.email }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.username }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.pic ? user.pic.name : "-" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ user.role ? user.role.name : "-" }}
                  </span>
                </td>

                <td class="pr-0">
                  <a
                    class="btn btn-light-success font-weight-bolder font-size-sm"
                    @click="showEditModal(user)"
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                    @click="showDeleteModal(user)"
                  >
                    <i class="flaticon-delete"></i>
                  </a>
                </td>
              </tr>
            </template>
          </complete-table>
        </div>
      </div>

      <b-modal ref="add-user" hide-footer title="Form Tambah User">
        <v-form @submit="addUser">
          <div class="d-block text-center">
            <div class="row">
              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Name *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Name"
                  v-model="form.name"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Pic *</label>
                <b-form-select
                  v-model="filter.pic"
                  :options="formattedDrafterPicOptions"
                  size="lg"
                  required
                ></b-form-select>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Email *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Email"
                  v-model="form.email"
                  type="email"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Username *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Username"
                  v-model="form.username"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Password *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Password"
                  v-model="form.password"
                  required
                  type="password"
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Role *</label>
                <b-form-select
                  v-model="form.role"
                  :options="formattedRoleOptions"
                  size="lg"
                  required
                ></b-form-select>
              </div>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              class="btn btn-success mr-2"
              block
              :disabled="loading"
              type="submit"
              >Save</b-button
            >

            <b-button
              class="btn btn-secondary mt-0"
              block
              :disabled="loading"
              @click="closeAddModal"
              >Cancel</b-button
            >
          </div>
        </v-form>
      </b-modal>

      <b-modal ref="edit-user" hide-footer title="Form Ubah User">
        <b-form @submit="updateUser">
          <div class="d-block text-center">
            <div class="row">
              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">PIC *</label>
                <b-form-select
                  v-model="form.pic"
                  :options="formattedDrafterPicOptions"
                  size="lg"
                  required
                ></b-form-select>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Name *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Name"
                  v-model="form.name"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Email *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Email"
                  v-model="form.email"
                  type="email"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Username *</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Username"
                  v-model="form.username"
                  required
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Password</label>
                <b-form-input
                  id="input-large"
                  size="lg"
                  placeholder="Password"
                  v-model="form.password"
                  type="password"
                ></b-form-input>
              </div>

              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Role *</label>
                <b-form-select
                  v-model="form.role"
                  :options="formattedRoleOptions"
                  size="lg"
                  required
                ></b-form-select>
              </div>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              class="btn btn-success mr-2"
              block
              :disabled="loading"
              type="submit"
              >Update</b-button
            >
            <b-button
              class="btn btn-secondary mt-0"
              block
              :disabled="loading"
              @click="closeEditModal"
              >Cancel</b-button
            >
          </div>
        </b-form>
      </b-modal>

      <b-modal ref="delete-user" hide-footer title="Hapus User">
        <div class="d-block text-center mb-5 pb-5 pt-5">
          Are You Sure Want To Delete User ?
        </div>

        <div class="d-flex mt-5">
          <b-button
            class="btn btn-success mr-2"
            block
            :disabled="loading"
            @click="deleteUser"
            >Delete</b-button
          >
          <b-button
            class="btn btn-secondary mt-0"
            block
            :disabled="loading"
            @click="closeDeleteModal"
            >Cancel</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style>
.v-application--wrap {
  min-height: 2vh !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: mediumseagreen;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px;
  padding: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 4px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PIC } from "../../../core/services/store/pic.module";
import { GET_DL_DESIGN_DRAFT_PIC } from "@/core/services/store/reporting-deliverable-design.module";
import { GET_SCOPE_CATEGORY } from "../../../core/services/store/scope-category.module";
import {
  GET_USERS,
  POST_USER,
  PUT_USER,
  DELETE_USER,
} from "@/core/services/store/user.module";
import { GET_ROLES } from "@/core/services/store/role.module";

import CompleteTable from "../../content/widgets/advance-table/CompleteTable";

export default {
  name: "user",
  components: { CompleteTable },
  data() {
    return {
      dialog: false,
      filter: {
        username: null,
        name: null,
        pic: null,
      },
      currentIndex: null,
      form: {
        name: null,
        email: null,
        username: null,
        pic: null,
        role: null,
      },
      dataset: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentUsers",
      "currentUserLoading",
      "currentPics",
      "currentRoles",
    ]),
    ...mapState({
      deliverableDesignDraftPic: (state) =>
        state.deliverableDesign.deliverableDesignDraftPic?.data,
    }),

    loading() {
      return this.currentUserLoading;
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopeCategories)) {
        scopes = this.scopeCategories.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },

    formattedDrafterPicOptions() {
      let drafter_pic = [];

      if (arrayMoreThanOne(this.deliverableDesignDraftPic)) {
        drafter_pic = this.deliverableDesignDraftPic.map((drafter_pic) => {
          return {
            value: drafter_pic.code,
            name: drafter_pic.name,
            text: drafter_pic.code + " - " + drafter_pic.name,
          };
        });
      }
      return [...drafter_pic];
    },

    formattedRoleOptions() {
      let roles = [];

      if (arrayMoreThanOne(this.currentRoles)) {
        roles = this.currentRoles.map((role) => {
          return {
            value: role.id,
            text: role.name,
          };
        });
      }

      return [{ value: null, text: "Choose Role" }, ...roles];
    },

    currentPicCode() {
      return this.filter.pic;
    },

    filterUser() {
      return this?.currentUsers;
    },
  },
  methods: {
    customFilterPIC(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    showAddModal() {
      this.form = {
        id: null,
        name: null,
        email: null,
        username: null,
        pic: null,
        role: null,
        password: null,
      };

      this.$refs["add-user"].show();
    },

    closeAddModal() {
      this.$refs["add-user"].hide();
    },

    fnFilterUser() {
      var username = this.filter.username
        ? this.filter.username.toLowerCase()
        : null;
      var name = this.filter.name ? this.filter.name.toLowerCase() : null;

      var finals = this.currentUsers?.filter(
        (user) =>
          (username ? user.username.toLowerCase().includes(username) : true) &&
          (name ? user.name.toLowerCase().includes(name) : true)
      );

      this.dataset = finals;
    },

    updatePicData(pics, index) {
      this.pics[index].name = this.formattedPicOptions.find(
        (x) => x.value === this.pics.code
      ).name;
    },

    addUser(event) {
      event.preventDefault();

      const roles = this.formattedRoleOptions.find(
        (role) => role.value === this.form.role
      );

      const clearForm = {
        name: this.form.name,
        email: this.form.email,
        username: this.form.username,
        password: this.form.password,
        role: {
          id: roles.value,
          name: roles.text,
        },
        pic: {
          code: this.form.code,
          name: this.form.name,
        },
      };

      this.$store.dispatch(POST_USER, clearForm).then(() => {
        this.closeAddModal();
      });
    },

    updateUser(event) {
      event.preventDefault();

      console.log("form:", this.form);
      console.log("form:", this.formattedPicOptions);

      const pics = this.formattedDrafterPicOptions.find(
        (pic) => pic.value === this.form.pic
      );

      const roles = this.formattedRoleOptions.find(
        (role) => role.value === this.form.role
      );

      const clearForm = {
        id: this.form.id,
        name: this.form.name,
        email: this.form.email,
        username: this.form.username,
        password: this.form.password,
        role: {
          id: roles.value,
          name: roles.text,
        },
        pic: {
          code: pics.value,
          name: pics.text,
        },
      };
      this.$store.dispatch(PUT_USER, clearForm).then(() => {
        this.closeEditModal();
      });
    },

    showEditModal(data) {
      console.log("dataupdate", data);
      Object.assign(this.form, {
        id: data.id,
        name: data.name,
        email: data.email,
        username: data.username,
        pic: data.pic_code,
        role: data.role.id,
        password: data.password,
      });

      this.$refs["edit-user"].show();
    },

    closeEditModal() {
      this.$refs["edit-user"].hide();
    },

    showDeleteModal(data) {
      Object.assign(this.form, {
        id: data.id,
        name: data.name,
        email: data.email,
        username: data.username,
        pic: data.pic.code,
        role: data.role.id,
        password: data.password,
      });

      this.$refs["delete-user"].show();
    },

    closeDeleteModal() {
      this.$refs["delete-user"].hide();
    },

    deleteUser() {
      this.$store
        .dispatch(DELETE_USER, {
          id: this.form.id,
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    getScopeCategories() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPE_CATEGORY, this.filter);
      }
    },

    getPics() {
      if (!arrayMoreThanOne(this.currentPics)) {
        this.$store.dispatch(GET_PIC);
      }
    },

    getDraftPic() {
      if (!arrayMoreThanOne(this.deliverableDesignDraftPic)) {
        this.$store.dispatch(GET_DL_DESIGN_DRAFT_PIC, {});
      }
    },

    getRoles() {
      if (!arrayMoreThanOne(this.currentRoles)) {
        this.$store.dispatch(GET_ROLES);
      }
    },

    getUsers() {
      if (!arrayMoreThanOne(this.currentUsers)) {
        this.$store.dispatch(GET_USERS);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User" }]);
    this.getRoles();
    this.getPics();
    this.getUsers();
    this.fnFilterUser();
    this.getDraftPic();
  },
  watch: {
    currentPicCode(value) {
      console.log("pic", value);
      if (!value) {
        Object.assign(this.form, {
          code: null,
          username: null,
          name: null,
          email: null,
        });
        return;
      }

      const pic = this.currentPics.find(
        (x) => x.code === this.filter.pic || x.code === this.form.pic
      );

      Object.assign(this.form, {
        code: pic.code,
        username: pic.username,
        name: pic.name,
        email: pic.email,
        ...pic,
      });
    },
    filterUser(value) {
      this.dataset = value;
    },
  },
};
</script>

<style></style>
